
.simon-says-module {
  & .content {
    position: relative;

    & .simon-button {
      position: absolute;

      & .cap {
        position: absolute;
        width: 100px;
        height: 100px;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        font-size: 28px;
        font-weight: bolder;
        text-align: center;
        color: #b2b2b2;
        border-radius: 10px;
        background: white -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
        border-color: #f2f2f2;
        border-style: solid;
        border-width: 1px;
        border-bottom-width: 4px;
        border-right-width: 2px;
        border-left-width: 2px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2), 2px 2px 4px rgba(0, 0, 0, 0.1), -2px 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transform: translateY(-5px);
        transition: all 100ms;

        & .hilight {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
          transition: all 100ms;
        }

        &:active {
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.7), 1px 2px 1px rgba(0, 0, 0, 0.4), -1px 2px 1px rgba(0, 0, 0, 0.4);
          background: -webkit-linear-gradient(top, #e5e5e5 0%, #d9d9d9 80%, #c7c7c7 100%);
          border-bottom-width: 3px;
          transform: translateY(0px);
          color: #939393;
          transition: all 100ms;

          & .hilight {
            box-shadow: inset 0 -5px 25px rgba(0, 0, 0, 0.2);
          }
        }

        &.active {
          transition: all 100ms;
        }
      }


      &.green-button {
        top: 30px;
        left: 70px;

        & .cap {
          background: linear-gradient(30deg, rgba(10, 143, 0, 1) 0%, rgba(41, 217, 0, 1) 100%);
          border-color: #a5ee67;
          border-top-color: #eaff8c;
          border-bottom-color: #61d210;
        }

        &.active {
          & .cap {
            background: linear-gradient(30deg, rgba(0, 223, 14, 1) 0%, rgba(176, 255, 0, 1) 100%);
          }

          & .hilight {
            box-shadow: 0 0 10px rgb(255, 251, 103), 0 0 10px rgb(255, 251, 103);
          }
        }
      }

      &.red-button {
        top: 30px;
        left: 205px;

        & .cap {
          background: linear-gradient(30deg, rgba(144, 31, 0, 1) 0%, rgba(255, 16, 0, 1) 100%);
          border-color: #ff976a;
          border-top-color: #fff08c;
          border-bottom-color: #ff6e1e;
        }

        &.active {
          & .cap {
            background: linear-gradient(30deg, rgb(255, 56, 0) 0%, rgb(255, 155, 5) 100%);
          }

          & .hilight {
            box-shadow: 0 0 10px rgb(255, 166, 129), 0 0 10px rgb(255, 204, 110);
          }
        }
      }

      &.yellow-button {
        top: 165px;
        left: 70px;

        & .cap {
          background: linear-gradient(30deg, rgba(193, 133, 4, 1) 0%, rgba(255, 201, 0, 1) 100%);
          border-color: #ffd56a;
          border-top-color: #fff7bc;
          border-bottom-color: #ffa51e;
        }

        &.active {
          & .cap {
            background: linear-gradient(30deg, rgba(247, 182, 0, 1) 0%, rgba(241, 255, 0, 1) 100%);
          }

          & .hilight {
            box-shadow: 0 0 10px rgb(255, 166, 129), 0 0 10px rgb(255, 204, 110), 0 0 10px rgb(255, 204, 110);
          }
        }
      }

      &.blue-button {
        top: 165px;
        left: 205px;

        & .cap {
          background: linear-gradient(30deg, rgba(0, 26, 171, 1) 0%, rgba(0, 108, 255, 1) 100%);
          border-color: #6abcff;
          border-top-color: #dcfbff;
          border-bottom-color: #1e80ff;
        }

        &.active {
          & .cap {
            background: linear-gradient(30deg, rgb(0, 142, 248) 0%, rgba(0, 198, 255, 1) 100%);
          }

          & .hilight {
            box-shadow: 0 0 10px rgb(216, 250, 253), 0 0 10px rgb(110, 255, 255), 0 0 10px rgb(110, 255, 255);
          }
        }
      }
    }

    & .step-indicator {
      & div {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: solid 2px white;
        left: 320px;
        box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);

        &.active {
          background-color: white;
        }

        &.failed {
          background-color: red;
        }
      }

      & :nth-child(6) {
        top: 60px;
      }

      & :nth-child(5) {
        top: 90px;
      }

      & :nth-child(4) {
        top: 120px;
      }

      & :nth-child(3) {
        top: 150px;
      }

      & :nth-child(2) {
        top: 180px;
      }

      & :nth-child(1) {
        top: 210px;
      }
    }

    & .steps-label {
      width: 150px;
      transform: rotate(-90deg);
      position: absolute;
      top: 130px;
      left: 275px;
    }
  }
}