.binary-logic-module .content {
  position: relative;

  & .display {
    top: 12px;
    left: 12px;
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #646464;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 6px;
    box-shadow: 0 4px 1px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.4);

    & div {
      width:18px;
      height: 18px;
      border-radius: 3px;
      transition: all 300ms;
      background-color: #3d3d3d;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

      &.active-1 {
        background-color: white;
        box-shadow: 0 0 3px white, 0 0 4px white;
      }
    }
  }

}