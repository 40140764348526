$module-width: 420px;
$module-height: 300px;

.label {
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.box-center-wrapper {
  box-shadow: inset 0 0 204px 68px rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 0;
  margin: 0;
}

.device {
  padding: 6px;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto 0;

  &.device-width-1 {
    max-width: ($module-width * 1)+10;
  }

  &.device-width-2 {
    max-width: ($module-width * 2)+30;
  }

  &.device-width-3 {
    max-width: ($module-width * 3)+50;
  }

  &.device-width-4 {
    max-width: ($module-width * 4)+80;
  }

  & .module {
    box-sizing: border-box;
    background: lightgrey;
    width: $module-width;
    height: $module-height;
    min-width: $module-width;
    min-height: $module-height;
    margin: 4px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border: 1px solid #e7e7e7;
    border-bottom: 2px solid #8f8e8e;
    border-right: 1px solid #8f8e8e;
    border-top: 1px solid #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 1);

    & .content {
      flex-grow: 1;
      height: 100%;
      background: #c2c2c2;
      background: linear-gradient(325deg, rgba(177,177,177,1) 0%, rgba(205,205,200,1) 100%);
    }
  }
}

@media only screen and (max-width: 859px) {
  .device-width-2 > .timer-module {
    order: -20;
  }

  .device {
    padding: 0;
  }

  .device > .module {
    margin: 6px 0;
  }
}

@media only screen and (max-width: 1282px) {
  .device-width-3 > .timer-module {
    order: -20;
  }
}

@media only screen and (max-width: 1706px) {
  .device-width-4 {
    max-width: ($module-width * 2)+80 !important;
  }
  .device-width-4 > .timer-module {
    order: -20;
  }
}

.module-status-indicator {
  height: 100%;
  background: #7f8288;
  background: linear-gradient(325deg, rgba(127,130,136,1) 0%, rgba(158,158,158,1) 100%);
  width: 35px;
  position: relative;

  & .led {
    position: absolute;
    width: 10px;
    height: 20px;
    background: #565555;
    left: 12px;
    transition: all 200ms;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    border-radius: 2px;

    & div {
      transform: rotate(-90deg);
      position: relative;
      top: -20px;
    }

    &.armed {
      top: 100px;

      &.active {
        background: #ff0000;
        box-shadow: 0 0 12px 2px rgba(255, 140, 0, 0.9), 0 0 6px 2px rgba(255, 255, 0, 0.3), inset 0 0 4px 2px rgba(255, 255, 0, 0.4);
      }
    }

    &.disarmed {
      top: 250px;

      &.active {
        background: lightgreen;
        box-shadow: 0 0 12px 2px rgba(123, 255, 46, 0.9);
      }
    }
  }
}

.config-module {
  width: 150px;
  height: 100px;
  transition: all 200ms;
  position: relative;
  overflow: hidden;

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.key {
  width: 60px;
  height: 60px;
  position: absolute;

  & .cap {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 28px;
    font-weight: bolder;
    text-align: center;
    color: #b2b2b2;
    border-radius: 10px;
    background: white -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px;
    border-bottom-width: 4px;
    border-right-width: 2px;
    border-left-width: 2px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4), 2px 2px 4px rgba(0, 0, 0, 0.1), -2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 50ms;
    cursor: pointer;
    transform: translateY(-4px);

    &:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.9), 1px 2px 1px rgba(0, 0, 0, 0.6), -1px 2px 1px rgba(0, 0, 0, 0.6);
      background: -webkit-linear-gradient(top, #e5e5e5 0%, #d9d9d9 80%, #c7c7c7 100%);
      border-bottom-width: 3px;
      transform: translateY(0px);
      border-color: white;
      color: #6e6e6e;
    }
  }

  & .key-border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid white;
    border-radius: 10px;
  }
}

.config-device {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  & .config-device-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    &.device-width-1 {
      max-width: 208px;
    }

    &.device-width-2 {
      max-width: 416px;
    }

    &.device-width-3 {
      max-width: 632px;
    }

    &.device-width-4 {
      max-width: 864px;
    }

    & .config-module {
      width: 200px;
      height: 140px;
      background-color: #e8e8e8;
      border-radius: 8px;
      margin: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
    }

  }
}

.framed-label {
  position: absolute;
  //border: dotted 1px blue;

  & .with-border {
    pointer-events: none;
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 2px rgba(255, 255, 255, 0.8);
    //background-color: rgba(255,0,0,0.5);
  }

  & .label-wrapper {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    overflow: visible;

    & .label {
      overflow: visible;
      position: relative;
      //background-color: #c2c2c2;
      //background-color: red;
      padding-left: 4px;
      padding-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      //clip-path: ellipse(50% 70%);
    }
  }
}

.seven-segment-body {
  position: absolute;
  background-color: #505050;
  width: 70px;
  height: 120px;
  border-radius: 3px;

  & .seven-segment {
    position: absolute;
    transform: skew(-6deg);

    & .glow-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 300ms;

      & .segment {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #838383;
        transition: background-color 200ms;
      }

      &.active {
        filter: drop-shadow(0 0 3px rgba(255, 255, 0, 1));

        & .segment {
          background-color: yellow;
        }
      }
    }
  }
}

.technical-line {
  pointer-events: none;
  position: absolute;
  //border: dashed 1px red;
  opacity: 0.8;
}

.screw {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #adadad; /* Old browsers */
  background: linear-gradient(135deg, #adadad 0%,#e1e1e1 51%,#dddddd 68%,#f6f6f6 100%); /* W3C */
  -moz-box-shadow: 0 2px 4px #000;
  -webkit-box-shadow: 0 2px 4px #000;
  box-shadow: 0 2px 4px #000, -1px -1px 5px rgba(0,0,0,0.2);
  border: 1px solid rgba(255,255,255,0.1);

}
.indent {
  height: 7px;
  width: 22px;
  margin-top: 5px;
  -moz-box-shadow: inset 0 1px 8px #222;
  -webkit-box-shadow: inset 0 1px 8px #222;
  box-shadow: inset 0 1px 8px #222;
  transform:rotate(150deg);
  border-radius: 2px;
  margin-left:-2px;
  border-bottom:1px solid rgba(255,255,255,0.3);

}
