@keyframes blinkingBackground {
  0% {
    background-color: rgba(120, 0, 0, 0.1);
  }
  25% {
    background-color: rgba(120, 0, 0, 0.7);
  }
  50% {
    background-color: rgba(120, 0, 0, 0.1);
  }
  75% {
    background-color: rgba(120, 0, 0, 0.7);
  }
  100% {
    background-color: rgba(120, 0, 0, 0.1);
  }
}

.failed-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: blinkingBackground 2s infinite;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  & .center-wrapper {
    position: relative;
    width: 140px;
    height: 100px;
  }
}

.timer-module {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow: visible !important;

  & .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    opacity: 1;

    & .timer-slanted-border {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.7;
    }

    &.sticky {
      display: none;
    }
  }

  &.sticky {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.sticky-timer-module {
    & .sticky {
      display: block;
    }

    & .regular {
      display: none;
    }
  }
}

.seven-segment-timer {
  position: absolute;
  top: 125px;
  left: 84px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: start;
  gap: 8px;

  & .digit {
    position: relative;
    box-shadow: -1px 4px 1px rgba(0, 0, 0, 0.2), 1px 4px 1px rgba(0, 0, 0, 0.2);
  }

  & .divider {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    //border: dotted 1px blue;
    gap: 30%;
  }

  & .diode {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #757575;
    transition: all 300ms;

    &.active {
      background-color: yellow;
      box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.1);
      filter: drop-shadow(0 0 4px rgba(255, 255, 0, 1));
    }

    &.p1 {
      left: 117px;
      top: 20px;
    }

    &.p2 {
      left: 117px;
      top: 60px;
    }
  }
}

.card-slot {
  position: absolute;
  top: -60px;
  width: 240px;
  height: 100px;
  //border: dashed 1px blue;

  & .slot-opening {
    position: absolute;
    top: 118px;
    left: -10px;
    width: 260px;
    height: 25px;
    background-color: #6b6b6b;
    border-radius: 8px;
    box-shadow: 0 -5px 10px 2px rgba(0, 0, 0, 0.75) inset;
    border-top: solid 2px rgba(77, 77, 77, 0.73);
    border-bottom: solid 2px rgba(255, 255, 255, 0.55);
  }

  & .slot-opening-border {
    position: absolute;
    top: 109px;
    left: -20px;
    width: 280px;
    height: 45px;
    background-color: #858585;
    border-radius: 8px;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.4);
    border-bottom: solid 2px rgba(40, 40, 40, 0.6);
  }

  & .card-3d-container {
    position: absolute;
    width: 240px;
    height: 140px;
    perspective: 800px;
    //border: dashed 1px blue;

    & .card-3d {
      cursor: pointer;
      position: absolute;
      width: 240px;
      height: 140px;
      border-radius: 12px 12px 0 0;
      border-top: solid 8px white;
      transform: rotateX(-65deg);
      transform-origin: bottom center;
      box-shadow: 0 -10px 60px -10px rgba(0, 0, 0, 0.6);
      background: rgb(115, 115, 115);
      background: linear-gradient(-10deg, rgb(201, 201, 201) 0%, rgb(238, 238, 238) 30%, rgb(255, 255, 255) 70%, rgb(215, 215, 215) 100%);
      transition: all 400ms;
      overflow: hidden;

      &:hover {
        transform: rotateX(-40deg);
        border-top: solid 3px white;

        & .bottom-shadow {
          height: 25px;
        }

        & .overlay-shadow {
          opacity: 0;
        }

        & .inner-border {
          opacity: 0.7;
        }
      }

      & .bottom-shadow {
        position: absolute;
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;
        background: rgb(47, 47, 47);
        background: linear-gradient(0deg, rgba(47, 47, 47, 1) 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 1;
        transition: all 400ms;
      }

      & .overlay-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgb(120, 120, 120);
        background: linear-gradient(0deg, rgba(120, 120, 120, 0.2) 0%, rgba(0, 0, 0, 0.3029586834733894) 100%);
        transition: all 400ms;
      }

      & .inner-border {
        position: absolute;
        border-radius: 10px 10px 0 0;
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 0;
        border: solid 5px black;
        border-bottom: none;
        opacity: 0.5;
        transition: all 400ms;

        & .difficulty-label {
          color: black;
          position: absolute;
          width: 100%;
          height: 10px;
          left: 0;
          top: 6px;
          display: flex;
          justify-content: center;
          font-weight: bolder;
          font-size: 13px;
        }

        & .difficulty-text {
          color: black;
          position: absolute;
          width: 100%;
          height: 10px;
          left: 0;
          top: 30px;
          display: flex;
          justify-content: center;
          font-weight: bolder;
          font-size: 20px;

          & svg {
            padding-left: 2px;
            padding-right: 2px;
          }
        }

        & .serial-number-label {
          color: black;
          position: absolute;
          width: 100%;
          height: 10px;
          left: 0;
          top: 65px;
          display: flex;
          justify-content: center;
          font-weight: bolder;
          font-size: 13px;
        }

        & .serial-number-text {
          color: black;
          position: absolute;
          width: 100%;
          height: 10px;
          left: 0;
          top: 70px;
          display: flex;
          justify-content: center;
          font-weight: bolder;
          font-size: 40px;
        }
      }
    }
  }
}

.sticky-timer-module {
  position: sticky;
  top: -240px;
  z-index: 500;
  box-shadow: 0 2px 3px rgb(0 0 0), 0 5px 20px rgba(0, 0, 0, 0.5) !important;

  & .serial-number {
    position: absolute;
    height: auto;
    bottom: 28px;
    left: 28px;
    color: rgba(0,0,0,0.5);
    font-size: 22px;
    line-height: 22px;
    font-weight: 900;
  }

  & .difficulty-level {
    position: absolute;
    height: auto;
    bottom: 25px;
    right: 15px;
    display: flex;
    gap: 4px;
    opacity: 0.5;
    transform: scale(0.8);
  }
}