
.card-module {
  position: relative;

  & .number-display {
    position: absolute;
    top: 20px;
    left: 65px;
    width: 260px;
    height: auto;
    display: flex;
    justify-content: space-between;

    & .digit {
      position: relative;
      box-shadow: -1px 4px 1px rgba(0,0,0,0.2), 1px 4px 1px rgba(0,0,0,0.2);
    }
  }

  & .numbers-input{
    position: absolute;
    border: solid 1px white;
    border-radius: 8px;
    width: 150px;
    height: 40px;
    top: 50px;
    left: 125px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bolder;
    font-size: 26px;
    color: #626262;
    background-color: rgba(0,0,0,0.1);
  }

  & .keypad {
    position: absolute;
    top: 100px;
    left: 70px;
  }
}